import React,  { useState, lazy, Suspense } from 'react'
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { clearSearch, getDataByType , searchToggle} from '../stores/actions/common-action'
import { openAuthModal } from '../stores/actions/auth-action';
import { RouteNames } from '../includes/route-names';
import { useHistory } from "react-router-dom";

const AskExpert = lazy(() => import('./ask-expert') );
const GoogleSearchAutocomplete = lazy(() => import('./google-search-autocomplete') );

const SearchBar = (props) => {

    const location = useLocation();
    const [width, setWidth] = useState( 0 );
    const csrfToken = useSelector(state => state.auth.csrf_token);
    const { body, field_country_value } = queryString.parse( location.search, true);
    const { is_expert_ask, onSearchSubmit, removeLocation, removeClass, keyword } = props;
    const resetSearch = useSelector( state => state.common.resetSearch );
    const [ showClearBtn, setShowClearBtn ] = useState( keyword ? true : ( field_country_value ? true : ( body ? true : false ) )  );
    const [ removeLoc, setRemoveLoc ] = useState( false );
    const [ keywords, setKeywords ] = useState( ( keyword || body ) || '');
    const [ locationData, setLocationData ] = useState({});
    const dispatch = useDispatch();
    const [show, setShow] = useState( false );
    const history = useHistory();
    const [pageType, setPageType] = useState('products');
    const routeNames = {'products': RouteNames.buy,'smart-solutions':RouteNames.smart_solution,'bike-enthusiasts':RouteNames.bikeEnthusticList,
                        'event':RouteNames.event, 'biking-destinations':RouteNames.biking}
    const [allVendorForm, setAllVendorForm] = useState({
                            destination: RouteNames.destinationForm, enthusiasts: RouteNames.bikeEnthusticForm,
                            product: RouteNames.buy_form, event: RouteNames.eventForm, solutions: RouteNames.smart_solution_form, services: RouteNames.serviceForm
                        });
    const userForms = localStorage.getItem('user_forms') ? JSON.parse(localStorage.getItem('user_forms')) : ''
    
    // const [searchForm, setSearchForm] = useState({
    //     rent:'',
    //     search_key:''
    // });

  const handleShow = () => setShow(true);


  React.useEffect(()=>{
      if( resetSearch ){
        setKeywords( '' );
        setShowClearBtn( false );
      }
      setWidth( window.screen.width );
  },[resetSearch]);


//   const handleInput =(e)=>{
//     setSearchForm({...searchForm, [e.target.name]: e.target.value})
//   }

    //console.log( 'RESET SEARCH ===== ', resetSearch );

    const changeInputHandler = ( ev ) =>{
        setShowClearBtn( true );
        setKeywords( ev.target.value );
    }


    const searchSubmitHandler = (evt) =>{
        evt.preventDefault();
        if(props.is_home){
            let queryData ={};
            if( keywords ){
                queryData['body'] = keywords;
            }
            if( locationData && locationData.location ){
                queryData['field_country_value']= locationData.location;
            }
            var queryString = '';
            Object.entries(queryData).forEach((data, index)=>{
                if(index == 0){
                    queryString += `?${data[0]}=${data[1]}`
                }else{
                    queryString += `&${data[0]}=${data[1]}`
                }
                
            })
            history.push(routeNames[pageType] + queryString)
        }else{
            onSearchSubmit( {
                keywords,
                locationData
            } );
        }
        


    }

    const _googleSearchTouchHandler = ( status )=>{
        setShowClearBtn( status );
    }

    const _handleClearButton = () =>{
        setKeywords('')
        setLocationData( {} );
        setRemoveLoc( true );
        setShowClearBtn( false );
        dispatch( clearSearch( true ) );
    }

    const mapDataHandler = ( data ) =>{
                
        //setLocationData( { ...data });
        //console.log("DATA", data );
        if( Object.keys(data).length > 0)
            setLocationData({location: data });
        else
            setLocationData({});
    }

    const handleUserForm =()=>{
        if (!csrfToken) {
            dispatch(openAuthModal('login'))
        }
        if(userForms && userForms.length > 0 ){
            history.push(allVendorForm[userForms[0].name])           
        }
    }


    return (
        <>
            <div className={ removeClass ? `${removeClass} bannerform wow fadeIn` : "bannerform wow fadeIn" } data-wow-duration="1s" data-wow-delay=".5s">
                <form onSubmit={searchSubmitHandler}>
                    {
                        props.is_home &&
                        <div className="form-group widthmin">
                            <select id="searchselector" name="pageType" value={pageType} onChange={(e)=>setPageType(e.target.value)}>
                                <option value="products">Products</option>
                                <option value="smart-solutions">Smart Solutions</option>
                                <option value="bike-enthusiasts">Bike Enthusiasts</option>
                                <option value="event">Events</option>
                                <option value="biking-destinations">Biking Destionations</option>
                            </select>
                        </div>
                    }
                    
                    <div className="form-group widthsm">
                        <input type="" name="" placeholder="Search by keywords" value={keywords} onChange={changeInputHandler} />
                    </div>
                    
                    { removeLocation ? null : 
                    <Suspense fallback={<p/>}>
                        <GoogleSearchAutocomplete onInput={mapDataHandler} onReset={removeLoc} touchHandler={_googleSearchTouchHandler} /> 
                    </Suspense>
                    }
                    <div className="form-group">
                        <input type="submit" name="" value="Search" />
                    </div>
                    {
                        showClearBtn &&
                        <div className="form-group">
                            <input type="reset" name="" value="Clear" onClick={_handleClearButton} />
                        </div>
                    }
                    { width > 767 &&
                        <div className="form-group gtfrm">
                            <Link className="getexpert" to="#" onClick={handleUserForm}>
                                <img src={require('../assets/images/expertIcon.png')} alt="expertIcon" />
                                <span>
                                    List Yourself With CarryOn
                                </span>
                            </Link>
                        </div>
                    }
                    {/* {
                        is_expert_ask &&
                        <div className="form-group gtfrm">
                            <Link className="getexpert" to="#" onClick={handleShow}>
                                <img src={require('../assets/images/expertIcon.png')} alt="expertIcon" />
                                <span>
                                    Ask an Expert
                                </span>
                            </Link>
                        </div>
                    } */}

                </form>
            </div>
            {
                is_expert_ask && 
                <Suspense fallback={<div/>}>
                    <AskExpert showExpert={show} handleModal={() => setShow( false ) }/>
                </Suspense>
                
            }
        </>
    )
}

export default SearchBar
